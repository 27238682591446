import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Flex from '../../../components/base/Flex';
import InputAdornment from '@mui/material/InputAdornment';
import useDebounce from '../../../utils/debounce';
import _ from 'lodash';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import DuplicateGroup from './DuplicateGroup';
import ContainedButton from '../../../components/base/ContainedButton';
import OutlinedButton from '../../../components/base/OutlinedButton';
import TextButton from '../../../components/base/TextButton';
import useAwaitModal from '../../../hooks/useAwaitModal';
import CreateSystemVariable from './CreateSystemVariable';
import CreateStepVariable from './CreateStepVariable';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import InsertVariable from './InsertVariable';
import AddToGroup from './AddToGroup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EditSvg from '../../../components/svg/EditSvg';
import { useTheme } from '@emotion/react';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import PremiumBadge from '../../../components/base/PremiumBadge';
import NewFeatureBadge from '../../../components/base/NewFeatureBadge';

/** condition looks like:
 * {
 *         "type": "element" | 'skip' | 'url' | 'title',
 *         "visibility"?: "visible" | not-visible,
 *         "subType"?: 'text'
 *         "urlPattern"?: "A valid regex"
 *         "pageTitle"?: "A valid regex"
 *         "element"?: {
 *             "classes": {},
 *             "customSelector": null,
 *             "id": "",
 *             "scrollIntoView": true,
 *             "selector": "header:nth-child(1) > div.container > nav.navbar > ul.nav-list > li:nth-child(2) > a:nth-child(1)",
 *             "tagName": "A",
 *             "text": "Explore",
 *             "xpathLocator": "/html/body/header/div/nav/ul/li[2]/a"
 *         }
 */

const getNonSelectorElementProps = (element) => {
  return {
    scrollIntoView: element?.scrollIntoView,
    text: element?.text
  };
};
const getPreConditionMode = (conditions) => {
  if (!conditions?.length) return 'always-run';
  const condition = conditions[0];
  if (condition.type === 'element') {
    if (condition.subType === 'text') return 'element-visible-text';
    return `element-${condition.visibility || 'visible'}`;
  }
  return condition.type;
};

const SettingTextInput = ({ value, label, onChange }) => {
  return (
    <TextField
      size="small"
      fullWidth
      label={label}
      value={value}
      sx={{
        height: { xs: 1, sm: 1 },
        my: 1
      }}
      onChange={onChange}
    />
  );
};

// TODO: show configuration errors on fields with invalid config (input fields in preconditions). Also event step cards should show a validation error if their config has error
const TestRunSettingsBar = ({
  handleDelete,
  afterDelete,
  onClose,
  // Parent group is the group that contains this event, it is nullable, in which case the step belongs to a test.
  parentGroup,
  event: _event,
  onUpdateEvent,
  onUpdateGroup,
  pasteEventInParent,
  afterGroupDuplicated,
  events,
  activeEventIndex,
  testCaseVariables,
  dataSource,
  testGroups,
  onGroupAssociated,
  globalVariables,
  requestCustomExpressionPreconditionModal,
  requestJavascriptStepModal,
  ...boxProps
}) => {
  const isGroup = _event?.type === 'group';
  const isEventInGroup = !!parentGroup;
  const { testId } = useParams();

  // keep a local copy of localStepDetails for group events, or the event itself for non-group events that are part of a group.
  const [localStepDetails, setLocalStepDetails] = React.useState(_event);

  const [cursorIndex, setCursorIndex] = React.useState(null);

  const [editingItem, setEditingItem] = React.useState(null);
  const theme = useTheme();

  const { isFeatureEnabled: enabledCustomPreconditionsFeature } = useFeatureEnabled('custom-code');

  // This is just because groups can be large and updating the upper state could be slow, so we keep a local copy of say the step in the group, but only update the parent group at debounced intervals
  const debouncedLocalStepDetails = useDebounce(localStepDetails, 500);

  const { requestConfirm, ConfirmationDialog: ConfirmDeleteStep } = useConfirmDialog();
  const {
    requestConfirm: requestConfirmStepVariableDeletion,
    ConfirmationDialog: ConfirmDeleteStepVariable
  } = useConfirmDialog();
  const {
    requestConfirm: requestConfirmFileStepDeleteItem,
    ConfirmationDialog: ConfirmFileStepDeleteItem
  } = useConfirmDialog();

  const event = isGroup || isEventInGroup ? localStepDetails : _event;
  const eventMeta = (isGroup ? event.meta : event) || {};

  const saveGroup = () => {
    const data = isEventInGroup
      ? {
          ...parentGroup,
          steps: parentGroup.steps.map((step) =>
            step.id === _event.id ? debouncedLocalStepDetails : step
          )
        }
      : debouncedLocalStepDetails;

    onUpdateGroup({
      id: data.id,
      title: data.title,
      steps: data.steps,
      meta: data.meta
    });
  };

  const deleteStep = () => {
    if (isEventInGroup) {
      const newSteps = parentGroup.steps.filter((step) => step.id !== _event.id);
      if (newSteps.length === 0) handleDelete();
      onUpdateGroup({
        id: parentGroup.id,
        steps: newSteps
      });
    } else {
      handleDelete();
    }
    afterDelete();
  };

  const moveEventToParent = () => {
    if (!isEventInGroup || !parentGroup) return;
    // remove event from group
    const newSteps = parentGroup.steps.filter((step) => step.id !== _event.id);
    // paste event in parent
    pasteEventInParent(_event);
    onUpdateGroup({
      id: parentGroup.id,
      steps: newSteps
    });
  };

  // update group info on debouncedLocalStepDetails change
  useEffect(() => {
    if (
      (isGroup || isEventInGroup) &&
      debouncedLocalStepDetails?.id &&
      !_.isEqual(debouncedLocalStepDetails, _event)
    ) {
      saveGroup();
    }
  }, [debouncedLocalStepDetails]);

  const setPreConditionMode = (value) => {
    if (value === 'always-run') return handleUpdate({ conditions: undefined });
    if (value === 'custom-expression')
      return handleUpdate({
        conditions: [
          {
            type: 'custom-expression',
            text: ''
          }
        ]
      });
    if (value.indexOf('element') === 0) {
      const condition = {
        ...(eventMeta.conditions?.[0] || {}),
        ...(value === 'element-visible-text'
          ? { text: eventMeta.conditions?.[0]?.element?.text || eventMeta?.element?.text || '' }
          : {}),
        type: 'element',
        visibility: value.slice(8),
        subType: value === 'element-visible-text' ? 'text' : undefined,
        element: eventMeta.conditions?.[0]?.element || eventMeta.element
      };
      return handleUpdate({ conditions: [condition] });
    }

    const condition = {
      type: value,
      ...(value === 'url' ? { urlPattern: eventMeta.page?.url } : {}),
      ...(value === 'title' ? { pageTitle: eventMeta.page?.title } : {})
    };

    return handleUpdate({
      conditions: [condition]
    });
  };

  const preConditionMode = getPreConditionMode(eventMeta.conditions);

  const updateElement = (key, value) => {
    if (!eventMeta.conditions?.length) return;
    return handleUpdate({
      conditions: [
        {
          ...eventMeta.conditions[0],
          ...(key === 'text' ? { text: value } : {}),
          element: {
            ...(key === 'selector'
              ? getNonSelectorElementProps(eventMeta.conditions[0].element)
              : eventMeta.conditions[0].element || {}),
            [key]: value
          }
        }
      ]
    });
  };

  const updateTitleOrUrlCondition = (key, value) => {
    if (!eventMeta.conditions?.length) return;
    return handleUpdate({
      conditions: [
        {
          ...eventMeta.conditions[0],
          [key]: value
        }
      ]
    });
  };

  const getElementSelectorInput = () => (
    <SettingTextInput
      label={'Element selector'}
      value={eventMeta.conditions?.[0].element?.selector}
      onChange={(e) => updateElement('selector', e.target.value)}
    />
  );

  const handleUpdate = ({ title, ...otherFields }) => {
    if (isGroup || isEventInGroup) {
      return setLocalStepDetails({
        ...localStepDetails,
        ...otherFields,
        ...(!!title || !isGroup
          ? { title: title || localStepDetails.title, ...otherFields }
          : {
              meta: {
                ...localStepDetails.meta,
                ...otherFields
              }
            })
      });
    }

    onUpdateEvent({
      ...event,
      title: title ? title : event.title,
      ...otherFields
    });
  };

  const assertionTypes = [
    { label: 'Page title should be', value: 'page-title' },
    { label: 'Page url should be', value: 'page-url' },
    { label: 'Page should contain text', value: 'page-text' },
    { label: 'Element should be visible', value: 'element-visible' },
    { label: 'Element should not be visible', value: 'element-not-visible' },
    { label: 'Element should contain text', value: 'element-contains-text' },
    { label: 'Element should not contain text', value: 'element-not-contains-text' },
    { label: 'Checkbox should be checked', value: 'checkbox-checked' },
    { label: 'Element is enabled', value: 'element-enabled' },
    { label: 'Element is disabled', value: 'element-disabled' },
    { label: 'Element has html property/value', value: 'element-html-property' },
    { label: 'Element has css property/value', value: 'element-css-property' },
    { label: 'Selected label', value: 'selected-label' },
    { label: 'Selected value', value: 'selected-value' }
  ];

  const extraTypeOptions = [
    { label: 'Entire', value: 'entire', regex: /^.*$/ },
    { label: 'First number', value: 'first_number', regex: /\d+/ },
    { label: 'Last number', value: 'last_number', regex: /\d+(?!.*\d)/ },
    { label: 'First date', value: 'first_date', regex: /\d{4}-\d{2}-\d{2}/ }, // YYYY-MM-DD format
    { label: 'Last date', value: 'last_date', regex: /\d{4}-\d{2}-\d{2}/ }, // YYYY-MM-DD format
    { label: 'Custom', value: 'custom', regex: '' }
  ];

  const isValidRegex = (pattern) => {
    try {
      new RegExp(pattern);
      return true;
    } catch (error) {
      return false;
    }
  };

  const [
    requestSystemVariableCreation,
    {
      open: openSystemVariableCreation,
      onClose: onCloseSystemVariableCreation,
      onComplete: completeSystemVariableCreation
    }
  ] = useAwaitModal();

  const [
    requestAssignVariableModal,
    {
      open: openAssignVariableCreation,
      onClose: onCloseAssignVariableCreation,
      onComplete: completeAssignVariableCreationModal
    }
  ] = useAwaitModal();

  const [
    requestInsertVariableModal,
    {
      open: openInsertVariable,
      onClose: onCloseInsertVariable,
      onComplete: completeInsertVariableModal
    }
  ] = useAwaitModal();

  const [
    requestAddToGroupModal,
    {
      open: addToGroupOpen,
      onClose: onCloseAddToGroup,
      onComplete: completeAddToGroupModal,
      ...otherProps
    }
  ] = useAwaitModal();

  // in a scenario where event is undefined, this mostly happens when a bulk deletion is made and the TestRunSettingBar is currently active, we need to call the afterDelete function to prevent a catastrophic app crash
  if (!event) return afterDelete();

  // step variables
  const stepVariables = events.flatMap((event, index) => {
    const variableAssignments = [];

    if (event.variable_assignment && activeEventIndex > index) {
      variableAssignments.push({ name: event.variable_assignment.name, context: 'step' });
    }

    if (event.steps && Array.isArray(event.steps)) {
      event.steps.forEach((step) => {
        if (step.variable_assignment?.name) {
          variableAssignments.push({ name: step.variable_assignment.name, context: 'step' });
        }
      });
    }

    return variableAssignments;
  });

  const STEP_VARIABLE = event?.variable_assignment?.name || '';

  // check for events that used a step variable
  const usedStepVariables = events.flatMap((step) => {
    const matchingSteps = [];
    if (step.uses_variables && Array.isArray(step.uses_variables)) {
      const matchedVariable = step.uses_variables.find((variable) => {
        return variable.context === 'step' && variable.name === STEP_VARIABLE;
      });

      if (matchedVariable) {
        matchingSteps.push(step);
      }
    }

    if (step.steps && Array.isArray(step.steps)) {
      step.steps.forEach((group_step) => {
        if (group_step.uses_variables) {
          const matchedVariable = group_step.uses_variables.find((variable) => {
            return variable.context === 'step' && variable.name === STEP_VARIABLE;
          });
          if (matchedVariable) matchingSteps.push(group_step);
        }
      });
    }
    return matchingSteps;
  });

  const getPropertyName = (event) => {
    const validTypes = ['change', 'textInput', 'editcontent'];

    return validTypes.includes(event.type) ? 'element' : 'expectedValue';
  };

  const propertyName = getPropertyName(event);

  const isJsStep = event.type === 'js-step';

  const shouldRenderExpectedValue = (event) => {
    return (
      event.type === 'assert' &&
      ![
        'checkbox-checked',
        'element-visible',
        'element-not-visible',
        'element-enabled',
        'element-disabled'
      ].includes(event.subtype)
    );
  };

  const shouldRenderExpectedProperty = (event) => {
    return (
      event.type === 'assert' &&
      (event.subtype === 'element-html-property' || event.subtype === 'element-css-property')
    );
  };

  const shouldRenderVariableButtons = (event) => {
    const validTypes = ['change', 'textInput', 'editcontent'];
    const invalidAssertSubtypes = [
      'checkbox-checked',
      'element-visible',
      'element-not-visible',
      'element-enabled',
      'element-disabled'
    ];

    return (
      validTypes.includes(event.type) ||
      (event.type === 'assert' && !invalidAssertSubtypes.includes(event.subtype))
    );
  };

  const shouldRenderAssignVariableButton = (event) => {
    const validTypes = ['change', 'textInput', 'editcontent', 'extract', 'js-step'];

    return validTypes.includes(event.type) || (event.type === 'click' && !!event.element?.text);
  };

  const isTextEvent = ['change', 'textInput', 'editcontent'].includes(event.type);

  // Handles editing, deleting and creating of file items for event types of file-input or file-drop
  const handleAddOrEditNewFile = (filesArray) => {
    if (!editingItem?.name || !editingItem?.url) {
      toast.error('Please enter a new name or url');
      return;
    }

    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    if (!urlRegex.test(editingItem?.url)) {
      toast.error('Invalid URL format. Please enter a valid file URL.');
      return;
    }

    const updatedFilesArray = [...filesArray];

    if (editingItem.index !== undefined) {
      updatedFilesArray[editingItem.index] = {
        ...updatedFilesArray[editingItem.index],
        name: editingItem.name,
        url: editingItem.url
      };
    } else {
      updatedFilesArray.push({ name: editingItem.name, url: editingItem.url });
    }

    handleUpdate({ files: updatedFilesArray });
    setEditingItem(null);
  };

  const handleDeleteFileItem = (index, filesArray) => {
    if (filesArray.length > 1) {
      const updatedFilesArray = filesArray.filter((_, i) => i !== index);
      handleUpdate({ files: updatedFilesArray });
    } else {
      toast.error(
        'You cannot delete the last file, you must at all times have a least one file item'
      );
    }
  };

  const onDeleteFileStepItem = async (index, filesArray) => {
    if (await requestConfirmFileStepDeleteItem()) return handleDeleteFileItem(index, filesArray);
  };

  return (
    <Box {...boxProps}>
      <Box
        sx={{
          width: { xs: '16rem', sm: '18rem' },
          border: `2px solid ${theme.palette.table.mobileOutline}`,
          borderRadius: '8px',
          px: { xs: 2, sm: 2 },
          py: { xs: 2, sm: 2 }
        }}>
        <Flex justifyContent={'space-between'} px={{ xs: 2, sm: 1 }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: { xs: '0.8rem', sm: '1.3rem' },
              color: theme.palette.text.custom,
              mt: { xs: 2, sm: '0.1rem' },
              md: { xs: 2, sm: 2 }
            }}>
            {isGroup ? 'Configure group' : 'Settings'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: { xs: 0.4, sm: 0.4 }
            }}>
            <IconButton
              aria-label="cancel"
              onClick={onClose}
              sx={{
                mr: { xs: 0.4, sm: 0.4 }
              }}>
              <CloseIcon
                color="disabled"
                sx={{
                  fontSize: 25
                }}
              />
            </IconButton>
          </Box>
        </Flex>
        <Box
          sx={{
            px: { xs: 2, sm: 1 }
          }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: { xs: '0.8rem', sm: '1rem' },
              color: theme.palette.text.custom,
              fontWeight: 'bold',
              mt: { xs: 1 }
            }}>
            Title
          </Typography>
          <TextField
            size="small"
            value={event.title}
            fullWidth
            sx={{
              height: { xs: 1, sm: 1 },
              mt: { xs: 1.5, sm: 1.5 },
              mb: { x: 1, sm: 2 }
            }}
            onChange={(e) => handleUpdate({ title: e.target.value })}
          />
          <Divider />
          {isTextEvent && (
            <>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  color: theme.palette.text.custom,
                  fontWeight: 'bold',
                  mt: { xs: 1 }
                }}>
                Text to Assign
              </Typography>
              <TextField
                size="small"
                value={event.element?.value}
                fullWidth
                multiline={true}
                sx={{
                  height: { xs: 1, sm: 1 },
                  mt: { xs: 1, sm: 1 },
                  mb: { x: 1, sm: 2 }
                }}
                onSelect={(e) => setCursorIndex(e.target.selectionStart)}
                onChange={(e) =>
                  handleUpdate({
                    element: { ...event.element, value: e.target.value }
                  })
                }
              />
              <Divider />
            </>
          )}

          {event.type === 'assert' && (
            <Box>
              <FormControl
                fullWidth
                size={'small'}
                sx={{
                  height: 1,
                  mb: 2
                }}>
                <InputLabel id={'assertions-select'}>Assertion type</InputLabel>
                <Select
                  value={event.subtype}
                  label={'Assertion type'}
                  labelId={'assertions-select'}
                  id={'assertions-select'}
                  MenuProps={{
                    elevation: 1
                  }}
                  renderValue={(selected) => selected}
                  onChange={(e) => handleUpdate({ subtype: e.target.value })}>
                  {assertionTypes.map((assertion) => (
                    <MenuItem key={assertion.value} value={assertion.value}>
                      {assertion.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider />
            </Box>
          )}

          {shouldRenderExpectedProperty(event) && (
            <>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  color: theme.palette.text.custom,
                  fontWeight: 'bold',
                  mt: { xs: 1 }
                }}>
                Expected property name
              </Typography>
              <TextField
                size="small"
                value={event?.expectedPropertyName}
                fullWidth
                sx={{
                  height: { xs: 1, sm: 1 },
                  mt: 1.5,
                  mb: 1
                }}
                onChange={(e) => handleUpdate({ expectedPropertyName: e.target.value })}
              />
            </>
          )}

          {shouldRenderExpectedValue(event) && (
            <>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  color: theme.palette.text.custom,
                  fontWeight: 'bold',
                  mt: { xs: 1 }
                }}>
                Expected value
              </Typography>
              <TextField
                size="small"
                value={event?.expectedValue}
                fullWidth
                multiline={true}
                // maxRows={3}
                sx={{
                  height: { xs: 1, sm: 1 },
                  mt: { xs: 1.5, sm: 1.5 },
                  mb: { x: 1, sm: 2 }
                }}
                onSelect={(e) => setCursorIndex(e.target.selectionStart)}
                onChange={(e) => handleUpdate({ expectedValue: e.target.value })}
              />
              <Divider />
            </>
          )}

          {event.type === 'assert' && event.subtype === 'checkbox-checked' && (
            <>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  color: theme.palette.text.custom,
                  fontWeight: 'bold',
                  mt: 2
                }}>
                Expected value
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby={'demo-controlled-radio-buttons-group'}
                  name={'controlled-radio-buttons-group'}
                  row
                  size={'small'}
                  value={event?.expectedValue}
                  onChange={(e) => handleUpdate({ expectedValue: e.target.value })}>
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.radio.main
                          }
                        }}
                      />
                    }
                    label={'True'}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.radio.main
                          }
                        }}
                      />
                    }
                    label={'False'}
                  />
                </RadioGroup>
              </FormControl>
              <Divider />
            </>
          )}

          {isJsStep && (
            <ContainedButton
              fullWidth
              sx={{ mt: 1.5 }}
              onClick={() =>
                requestJavascriptStepModal({
                  editMode: true,
                  onHandleUpdate: (code) => {
                    handleUpdate({ expression: code });
                  },
                  initialValue: event.expression
                })
              }>
              Edit JS Expression
            </ContainedButton>
          )}

          {!!event.variable_assignment && (
            <Box sx={{ my: 2 }}>
              <TextField
                size="small"
                value={event.variable_assignment.name}
                fullWidth
                label={'Variable'}
                sx={{
                  height: { xs: 1, sm: 1 }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={async () => {
                          const confirm = await requestConfirmStepVariableDeletion();
                          if (confirm) handleUpdate({ variable_assignment: undefined });
                        }}
                        edge="end">
                        <DeleteSvg width={16} height={18} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  readOnly: true
                }}
              />
              {!!usedStepVariables?.length && (
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontSize: { xs: '0.8rem', sm: '0.8rem' }
                  }}>
                  Used in {usedStepVariables.length}{' '}
                  {usedStepVariables.length > 1 ? 'steps' : 'step'}
                </Typography>
              )}
            </Box>
          )}

          {shouldRenderAssignVariableButton(event) && event.variable_assignment && (
            <Box>
              <FormControl
                fullWidth
                size={'small'}
                sx={{
                  height: 1,
                  mb: 1
                }}>
                <InputLabel id={'extract-select'}>Extract type</InputLabel>
                <Select
                  value={event?.variable_assignment?.extract_type || ''}
                  label={'Extract type'}
                  labelId={'extract-select'}
                  id={'extract-select'}
                  MenuProps={{
                    elevation: 1
                  }}
                  renderValue={(selected) => selected}
                  onChange={(e) => {
                    const selectedOption = extraTypeOptions.find(
                      (option) => option.value === e.target.value
                    );
                    handleUpdate({
                      variable_assignment: {
                        ...event.variable_assignment,
                        extract_type: e.target.value,
                        regex_string: selectedOption?.regex?.source
                      }
                    });
                  }}>
                  {extraTypeOptions.map((type) => (
                    <MenuItem divider key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {event?.variable_assignment?.extract_type === 'custom' && (
                <TextField
                  size="small"
                  label={'Custom regex string'}
                  value={event.variable_assignment?.regex_string}
                  fullWidth
                  onChange={(e) =>
                    handleUpdate({
                      variable_assignment: {
                        ...event.variable_assignment,
                        regex_string: e.target.value
                      }
                    })
                  }
                />
              )}

              {event?.variable_assignment?.regex_string &&
                !!isValidRegex(event?.variable_assignment?.regex_string) && (
                  <Typography
                    sx={{
                      textAlign: 'left',
                      fontSize: { xs: '0.8rem', sm: '0.8rem' },
                      mb: 1
                    }}>
                    Extracted value -{' '}
                    {(event.element?.value || event.element?.text)?.match(
                      event.variable_assignment.regex_string
                    ) || ''}
                  </Typography>
                )}

              <Divider />
            </Box>
          )}

          {shouldRenderVariableButtons(event) && (
            <Box>
              <OutlinedButton fullWidth onClick={() => requestSystemVariableCreation()}>
                Insert Function
              </OutlinedButton>
              <OutlinedButton sx={{ mt: 1 }} fullWidth onClick={() => requestInsertVariableModal()}>
                Insert Variable
              </OutlinedButton>
            </Box>
          )}

          {shouldRenderAssignVariableButton(event) && (
            <Box>
              {!event.variable_assignment && (
                <ContainedButton
                  fullWidth
                  sx={{ mt: 1.5 }}
                  onClick={() => requestAssignVariableModal()}>
                  Assign to variable
                </ContainedButton>
              )}
            </Box>
          )}

          {event.type !== 'group' && (
            <FormControl>
              <FormLabel
                id="Preconditions-controlled-radio-buttons-group"
                sx={{
                  height: { xs: 1, sm: 1 },
                  mt: { xs: 1.5, sm: 1.5 },
                  mb: { x: 1, sm: 2 }
                }}
                onChange={(e) =>
                  handleUpdate({ element: { ...event.element, value: e.target.value } })
                }
              />
              <Divider />
            </FormControl>
          )}
          {!isGroup && !isJsStep && (
            <>
              <Box>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontSize: { xs: '0.8rem', sm: '0.9rem' },
                    color: theme.palette.text.custom,
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem'
                  }}>
                  Target Element
                  <Tooltip
                    title={
                      <Typography fontSize={'10px'} color={'#fff'}>
                        You can specify a CSS selector to be used to find the target element. This
                        is helpful for when Scandium can't find the element on its own.{' '}
                        <Link
                          href={'https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors'}
                          target={'_blank'}
                          rel={'noopener'}
                          color={'#fff'}>
                          Learn more about CSS selectors here
                        </Link>
                      </Typography>
                    }>
                    <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  size={'small'}
                  label={'CSS selector'}
                  value={event.element?.selector}
                  fullWidth
                  sx={{
                    height: { xs: 1, sm: 1 },
                    mt: { xs: 1.5, sm: 1.5 },
                    mb: { x: 1, sm: 2 }
                  }}
                  onChange={(e) =>
                    handleUpdate({
                      element: { ...event.element, selector: e.target.value }
                    })
                  }
                />
                <Divider />
              </Box>

              <Box>
                <TextField
                  size={'small'}
                  label={'xpathLocator'}
                  value={event.element?.xpathLocator}
                  fullWidth
                  sx={{
                    height: { xs: 1, sm: 1 },
                    mt: { xs: 1.5, sm: 1.5 },
                    mb: { x: 1, sm: 2 }
                  }}
                  onChange={(e) =>
                    handleUpdate({
                      element: { ...event.element, xpathLocator: e.target.value }
                    })
                  }
                />
                <Divider />
              </Box>

              {(event.type === 'file-input' || event.type === 'file-drop') && (
                <Box mt={2}>
                  <Flex justifyContent={'space-between'}>
                    <Typography
                      sx={{
                        textAlign: 'left',
                        fontSize: { xs: '0.8rem', sm: '0.9rem' },
                        color: theme.palette.text.custom,
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                      }}>
                      Files
                    </Typography>
                    <Tooltip title={'Add a new file'}>
                      <IconButton
                        size={'small'}
                        onClick={() => setEditingItem({ name: '', url: '' })}>
                        <AddCircleOutlineIcon color={theme.palette.svg.main} />
                      </IconButton>
                    </Tooltip>
                  </Flex>
                  {editingItem && (
                    <Box my={1}>
                      <TextField
                        size={'small'}
                        label={'File name'}
                        value={editingItem?.name}
                        fullWidth
                        onChange={(e) =>
                          setEditingItem((prevItems) => ({ ...prevItems, name: e.target.value }))
                        }
                        sx={{ mb: 1.5 }}
                      />
                      <TextField
                        size={'small'}
                        label={'File url'}
                        value={editingItem?.url}
                        fullWidth
                        onChange={(e) =>
                          setEditingItem((prevItems) => ({ ...prevItems, url: e.target.value }))
                        }
                      />
                      <ContainedButton
                        fullWidth
                        sx={{ mt: 1 }}
                        onClick={() => handleAddOrEditNewFile(event.files)}>
                        {editingItem?.hasOwnProperty('index') ? 'Update file' : 'Add new file'}
                      </ContainedButton>
                      <OutlinedButton sx={{ mt: 1 }} fullWidth onClick={() => setEditingItem(null)}>
                        Cancel
                      </OutlinedButton>
                    </Box>
                  )}
                  <Flex flexDirection={'column'} alignItems={'normal'} mb={2}>
                    {event.files.map((file, index) => (
                      <Flex
                        key={index + file.name}
                        sx={{ bgcolor: theme.palette.background.reportCard, py: '6px' }}
                        justifyContent={'space-between'}>
                        <Typography
                          component={'a'}
                          download
                          href={file.url}
                          color={theme.palette.text.custom}
                          target={'_blank'}
                          sx={{
                            fontSize: '0.75rem'
                          }}>
                          {file.name}
                        </Typography>
                        <Flex columGap={0}>
                          <Tooltip title={'Edit file name and url'}>
                            <IconButton
                              size={'small'}
                              onClick={() => setEditingItem({ ...file, index })}>
                              <EditSvg width={14} height={14} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={'Delete this file'}>
                            <IconButton
                              size={'small'}
                              onClick={() => onDeleteFileStepItem(index, event.files)}>
                              <DeleteSvg width={18} height={14} />
                            </IconButton>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                  <Divider />
                </Box>
              )}

              <FormControl>
                <FormLabel
                  id="Preconditions-controlled-radio-buttons-group"
                  sx={{
                    fontWeight: 'bold',
                    color: theme.palette.text.custom,
                    mt: { xs: 2, sm: 2 }
                  }}>
                  Wait Mode
                </FormLabel>
                <Typography textAlign={'left'} sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Wait for
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      size={'small'}
                      onChange={(e) => handleUpdate({ mustBeVisible: e.target.checked })}
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      checked={!!eventMeta.mustBeVisible}
                      inputProps={{ 'arial-label': 'Element should be visible' }}
                    />
                  }
                  label={
                    <Typography
                      fontSize={13}
                      sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      Element to be visible
                      <Tooltip title="This specifies that the target element of this step must be visible i.e not hidden on page">
                        <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                      </Tooltip>
                    </Typography>
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      size={'small'}
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      onChange={(e) =>
                        // TODO: this is ignored at the moment because isGroup is required to be false here anyway. If that changes, we need to account for group and individual events
                        handleUpdate({
                          mustBeVisible: e.target.checked ? true : event.mustBeVisible,
                          mustBeActionable: e.target.checked
                        })
                      }
                      checked={!!eventMeta.mustBeActionable}
                      inputProps={{ 'arial-label': 'Element should be visible' }}
                    />
                  }
                  label={
                    <Typography
                      fontSize={12}
                      sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      Element to be actionable
                      <Tooltip title="This specifies that the target element of this step must be actionable. An actionable element is visible, not disabled, and not ‘readonly’.">
                        <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                      </Tooltip>
                    </Typography>
                  }
                />
              </FormControl>
              <Divider sx={{ mt: 2 }} />
            </>
          )}
          <FormControl>
            <FormLabel
              id="Preconditions-controlled-radio-buttons-group"
              sx={{
                fontWeight: 'bold',
                color: theme.palette.text.custom,
                mt: { xs: 2, sm: 2 }
              }}>
              Failure Mode
            </FormLabel>
            <Typography textAlign={'left'} sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
              When this step fails
            </Typography>
            <RadioGroup
              aria-labelledby="Preconditions-controlled-radio-buttons-group"
              name="Preconditions-controlled-radio-buttons-group"
              value={eventMeta.onError}
              onChange={(event) => handleUpdate({ onError: event.target.value })}>
              <FormControlLabel
                value="fail"
                control={
                  <Radio
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                    size="small"
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Fail test immediately
                    <Tooltip title="When this step fails, the test is marked as failed, and subsequent steps are not executed">
                      <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                    </Tooltip>
                  </Typography>
                }
              />
              <FormControlLabel
                value="continue"
                control={
                  <Radio
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                    size="small"
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Fail and continue
                    <Tooltip title="When this step fails, the test is marked as failed, but subsequent steps are executed.">
                      <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                    </Tooltip>
                  </Typography>
                }
              />
              <FormControlLabel
                value="ignore"
                control={
                  <Radio
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                    size="small"
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Ignore failure
                    <Tooltip title="The 'Ignore failure' mode will execute all test cases even when the extension report a failure">
                      <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                    </Tooltip>
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          <Divider sx={{ mt: 2 }} />
          <FormControl>
            <FormLabel
              id="Preconditions-controlled-radio-buttons-group"
              sx={{
                fontWeight: 'bold',
                color: theme.palette.text.custom,
                mt: { xs: 2, sm: 2 }
              }}>
              Pre-Condition Mode
            </FormLabel>
            <Typography textAlign={'left'} sx={{ fontSize: 13, opacity: 0.8 }}>
              When to run this step
            </Typography>
            <RadioGroup
              aria-labelledby="Preconditions-controlled-radio-buttons-group"
              name="Preconditions-controlled-radio-buttons-group"
              value={preConditionMode}
              onChange={(event) => setPreConditionMode(event.target.value)}>
              <FormControlLabel
                value="always-run"
                control={
                  <Radio
                    size="small"
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Always run
                    {/* <Tooltip title="The precondition mode 'Always run' mean no condition is to be check, scandium extension should run">
                      <InfoIcon sx={{ fontSize: '16px', color: '#444444' }} />
                    </Tooltip> */}
                  </Typography>
                }
              />
              <FormControlLabel
                value="skip"
                control={
                  <Radio
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                    size="small"
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Never run (Skip)
                    {/* <Tooltip title="The precondition mode 'Never run' means that scandium extension should not run this particular step">
                      <InfoOutlinedIcon sx={{ fontSize: '16px', color: '#444444' }} />
                    </Tooltip> */}
                  </Typography>
                }
              />
              <FormControlLabel
                value="element-visible"
                control={
                  <Radio
                    size="small"
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Element visible
                    <Tooltip
                      title={
                        <Typography fontSize={'12px'} color={'#fff'}>
                          Specify a{' '}
                          <Link
                            href={'https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors'}
                            target={'_blank'}
                            rel={'noopener'}
                            color={'#fff'}>
                            CSS selector,
                          </Link>{' '}
                          if this element is found on the page during execution, the step will be
                          executed, otherwise, it will be skipped
                        </Typography>
                      }>
                      <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                    </Tooltip>
                  </Typography>
                }
              />
              {preConditionMode === 'element-visible' && getElementSelectorInput()}
              <FormControlLabel
                value="element-not-visible"
                control={
                  <Radio
                    size="small"
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Element not visible
                    {/* <Tooltip title="The precondition mode 'Element not visible' check if an element is not visible before scandium extension runs, it will skip this step if the element is not found">
                      <InfoIcon sx={{ fontSize: '16px', color: '#444444' }} />
                    </Tooltip> */}
                  </Typography>
                }
              />
              {preConditionMode === 'element-not-visible' && getElementSelectorInput()}
              <FormControlLabel
                value="element-visible-text"
                control={
                  <Radio
                    size="small"
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Element contains text
                    {/* <Tooltip title="The precondition mode 'Element contains text' check if a block of text is visible before scandium extension runs, it will skip this step if the element is not found">
                      <InfoIcon sx={{ fontSize: '16px', color: '#444444' }} />
                    </Tooltip> */}
                  </Typography>
                }
              />
              {preConditionMode === 'element-visible-text' && (
                <>
                  {getElementSelectorInput()}
                  <SettingTextInput
                    label={'Text content'}
                    value={(isGroup ? event.meta : event)?.conditions[0].element?.text}
                    onChange={(e) => updateElement('text', e.target.value)}
                  />
                </>
              )}
              <FormControlLabel
                value="title"
                control={
                  <Radio
                    size="small"
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Page title matches
                    {/* <Tooltip title="The precondition mode 'Page title matches' check if the page title matches before scandium extension runs, it will skip this step if the page title do not match">
                      <InfoIcon sx={{ fontSize: '16px', color: '#444444' }} />
                    </Tooltip> */}
                  </Typography>
                }
              />
              {preConditionMode === 'title' && (
                <SettingTextInput
                  label={'Page Title'}
                  value={(isGroup ? event.meta : event)?.conditions[0]?.pageTitle}
                  onChange={(e) => updateTitleOrUrlCondition('pageTitle', e.target.value)}
                />
              )}
              <FormControlLabel
                value="url"
                control={
                  <Radio
                    size="small"
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Page URL matches
                    {/* <Tooltip title="The precondition mode 'Page URL matches' check if the page url matches before scandium extension runs, it will skip this step if the page url do not match">
                      <InfoIcon sx={{ fontSize: '16px', color: '#444444' }} />
                    </Tooltip> */}
                  </Typography>
                }
              />
              {preConditionMode === 'url' && (
                <SettingTextInput
                  label={'Page URL'}
                  value={(isGroup ? event.meta : event)?.conditions[0]?.urlPattern}
                  onChange={(e) => updateTitleOrUrlCondition('urlPattern', e.target.value)}
                />
              )}

              <FormControlLabel
                value="custom-expression"
                disabled={!enabledCustomPreconditionsFeature}
                control={
                  <Radio
                    size="small"
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                  />
                }
                label={
                  <Flex>
                    <Typography fontSize={13}>Custom</Typography>
                    {enabledCustomPreconditionsFeature ? (
                      <Flex>
                        <Tooltip title={'Click here to add a custom javascript code'}>
                          <IconButton
                            onClick={() => {
                              const conditions = event?.conditions[0];
                              requestCustomExpressionPreconditionModal({
                                onHandleUpdate: (obj) => handleUpdate(obj),
                                initialValue: conditions?.text || ''
                              });
                            }}
                            size={'small'}
                            disabled={preConditionMode !== 'custom-expression'}>
                            <Typography
                              color={'primary'}
                              sx={{ textDecoration: 'underline' }}
                              variant={'body2'}>
                              &lt;Edit&gt;
                            </Typography>
                          </IconButton>
                        </Tooltip>
                        <NewFeatureBadge />
                      </Flex>
                    ) : (
                      <PremiumBadge />
                    )}
                  </Flex>
                }
              />
            </RadioGroup>
          </FormControl>

          {event.type === 'scroll' && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  color: '#000000',
                  fontWeight: 'medium',
                  mt: { xs: 2, sm: 2 },
                  mb: 1
                }}>
                Scroll Position
              </Typography>
              <Flex>
                <TextField
                  size={'small'}
                  value={event?.x}
                  type={'number'}
                  fullWidth
                  label={'X'}
                  sx={{
                    height: { xs: 1, sm: 1 }
                  }}
                  onChange={(e) => handleUpdate({ x: e.target.value })}
                />
                <TextField
                  size={'small'}
                  value={event?.y}
                  label={'Y'}
                  type={'number'}
                  fullWidth
                  sx={{
                    height: { xs: 1, sm: 1 }
                  }}
                  onChange={(e) => handleUpdate({ y: e.target.value })}
                />
              </Flex>
            </>
          )}

          <Divider sx={{ my: 2 }} />
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: { xs: '0.8rem', sm: '1rem' },
              color: theme.palette.text.black_grey,
              fontWeight: 'medium',
              mt: { xs: 2, sm: 2 }
            }}>
            Pre-Step delay (ms)
          </Typography>
          <TextField
            size="small"
            fullWidth
            type={'number'}
            value={(isGroup ? event.meta : event)?.preStepDelay}
            sx={{
              height: { xs: 1, sm: 1 },
              mt: { xs: 1, sm: 1 },
              mb: { x: 1, sm: 2 }
            }}
            onChange={(e) => handleUpdate({ preStepDelay: parseInt(e.target.value) })}
          />
          {!isGroup && (
            <>
              <Divider />
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  color: theme.palette.text.black_grey,
                  fontWeight: 'medium',
                  mt: { xs: 2, sm: 2 }
                }}>
                Step timeout (ms)
              </Typography>
              <TextField
                size="small"
                fullWidth
                type={'number'}
                value={(isGroup ? event.meta : event)?.stepTimeout}
                onChange={(e) => handleUpdate({ stepTimeout: parseInt(e.target.value) })}
                sx={{
                  height: { xs: 1, sm: 1 },
                  mt: { xs: 1, sm: 1 },
                  mb: { x: 1, sm: 2 }
                }}
              />
              {!isJsStep && (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      onChange={(e) =>
                        handleUpdate({
                          element: {
                            ...(isGroup ? event.meta : event)?.element,
                            scrollIntoView: e.target.checked
                          }
                        })
                      }
                      checked={!!(isGroup ? event.meta : event)?.element?.scrollIntoView}
                      inputProps={{ 'arial-label': 'Scroll into view' }}
                    />
                  }
                  label="Scroll into view"
                />
              )}

              {isTextEvent && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.radio.main
                          }
                        }}
                        onChange={(e) =>
                          handleUpdate({
                            element: {
                              ...(isGroup ? event.meta : event)?.element,
                              use_paste: e.target.checked
                            }
                          })
                        }
                        checked={!!(isGroup ? event.meta : event)?.element?.use_paste}
                        inputProps={{ 'arial-label': 'Scroll into view' }}
                      />
                    }
                    label="Dispatch paste event"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.radio.main
                          }
                        }}
                        onChange={(e) =>
                          handleUpdate({
                            element: {
                              ...(isGroup ? event.meta : event)?.element,
                              use_blur: e.target.checked
                            }
                          })
                        }
                        checked={!!(isGroup ? event.meta : event)?.element?.use_blur}
                        inputProps={{ 'arial-label': 'Scroll into view' }}
                      />
                    }
                    label="Dispatch blur event"
                  />
                </>
              )}

              {!isJsStep && (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      onChange={(e) =>
                        handleUpdate({
                          element: {
                            ...(isGroup ? event.meta : event)?.element,
                            use_native_event: e.target.checked
                          }
                        })
                      }
                      checked={!!(isGroup ? event.meta : event)?.element?.use_native_event}
                      inputProps={{ 'arial-label': 'Scroll into view' }}
                    />
                  }
                  label="Use native event"
                />
              )}
            </>
          )}
          {isGroup && (
            <DuplicateGroup group={_event} afterDuplicateCreated={afterGroupDuplicated} />
          )}
          {isEventInGroup && (
            <Box>
              <TextButton
                onClick={moveEventToParent}
                color="primary"
                sx={{
                  mt: 2,
                  ml: -1
                }}>
                Move to Parent
              </TextButton>
            </Box>
          )}
          {!isGroup && !isEventInGroup && (
            <>
              {activeEventIndex !== 0 && (
                <Tooltip title={!testId ? 'Please save the test first' : ''}>
                  <div>
                    <TextButton
                      onClick={() => requestAddToGroupModal({ addSteps: false })}
                      disabled={!testId}>
                      Insert Group Before
                    </TextButton>
                  </div>
                </Tooltip>
              )}
              <Tooltip title={!testId ? 'Please save the test first' : ''}>
                <div>
                  <TextButton
                    onClick={() => requestAddToGroupModal({ addSteps: false, insertAfter: true })}
                    disabled={!testId}>
                    Insert Group After
                  </TextButton>
                </div>
              </Tooltip>
            </>
          )}
          <Box>
            <ContainedButton
              onClick={async () => {
                const confirm = await requestConfirm();
                if (confirm) deleteStep();
              }}
              color="error"
              sx={{
                mt: 2
              }}>
              Delete
            </ContainedButton>
          </Box>
        </Box>
      </Box>
      <ConfirmDeleteStep
        title={'Are you sure you want to delete this test step?'}
        description={'This action cannot be reversed and you may have to not record it again.'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
        cancelLabel={"Don't delete it"}
      />
      <ConfirmFileStepDeleteItem
        title={'Are you sure you want to delete this file?'}
        description={'This action cannot be reversed and you may have to not record it again.'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
        cancelLabel={"Don't delete it"}
      />
      <ConfirmDeleteStepVariable
        title={'Are you sure you want to delete this test step variable?'}
        description={
          'Please note that this step variable might be in use in other steps within the test case. Deleting it may lead to unexpected behavior or errors in those steps.'
        }
        confirmLabel={'Delete'}
        confirmColor={'error'}
        cancelLabel={"Don't delete"}
      />
      <CreateSystemVariable
        open={openSystemVariableCreation}
        onClose={onCloseSystemVariableCreation}
        handleUpdate={handleUpdate}
        inputValue={event.element?.value || event.expectedValue || ''}
        cursorIndex={cursorIndex}
        event={event}
        property={propertyName}
        onCompleteInsertion={completeSystemVariableCreation}
      />
      <CreateStepVariable
        open={openAssignVariableCreation}
        onClose={onCloseAssignVariableCreation}
        handleUpdate={handleUpdate}
        value={event.element?.value}
        onCompleteCreation={completeAssignVariableCreationModal}
        event={event}
        stepVariables={stepVariables}
      />
      <InsertVariable
        open={openInsertVariable}
        onClose={onCloseInsertVariable}
        handleUpdate={handleUpdate}
        onCompleteCreation={completeInsertVariableModal}
        event={event}
        cursorIndex={cursorIndex}
        stepVariables={stepVariables}
        inputValue={event.element?.value || event.expectedValue || ''}
        testCaseVariables={testCaseVariables}
        dataSource={dataSource}
        property={propertyName}
        globalVariables={globalVariables}
      />
      <AddToGroup
        open={addToGroupOpen}
        testGroups={testGroups}
        onStepsAdded={(group, didAddSteps, insertAfter) => {
          completeAddToGroupModal();
          onGroupAssociated(group, didAddSteps, insertAfter);
        }}
        onClose={onCloseAddToGroup}
        selectedSteps={[]}
        {...otherProps}
      />
    </Box>
  );
};

export default TestRunSettingsBar;
