import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import { useActiveProject } from '../../../store/projectState';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const ActionMenu = React.forwardRef(
  (
    {
      isLoading,
      onDelete,
      disabled,
      isDelete,
      folderId,
      testId,
      folderLink,
      testLink,
      onRename,
      onDuplicate,
      app,
      onMoveToFolder,
      requestTestCaseTagsCreationUploadModal
    },
    ref
  ) => {
    const { triggerProps, menuProps } = useMenu();
    const activeProject = useActiveProject();
    const theme = useTheme();

    return (
      <div>
        <IconButton
          ref={ref}
          aria-controls={menuProps.open ? 'action-menu' : undefined}
          aria-haspopup="true"
          disabled={isLoading || disabled}
          aria-expanded={menuProps.open ? 'true' : undefined}
          {...triggerProps}>
          {isLoading ? (
            <CircularProgress size={18} color={'primary'} />
          ) : (
            <MoreVert sx={{ color: theme.palette.svg.main }} />
          )}
        </IconButton>
        <Menu
          id="action-menu"
          {...menuProps}
          elevation={1}
          PaperProps={{
            style: {
              minWidth: 150
            }
          }}>
          {!app && (
            <MenuItem as={NavLink} sx={{ color: 'inherit' }} to={folderId ? folderLink : testLink}>
              {folderId ? 'Open' : 'Edit'}
            </MenuItem>
          )}
          {!folderId && (
            <>
              <MenuItem
                as={NavLink}
                sx={{ color: 'inherit' }}
                to={`/projects/${activeProject?.id}/mobile-test-runs/${testId || ''}`}>
                View Runs
              </MenuItem>
              <MenuItem onClick={onDuplicate}>Duplicate</MenuItem>
              <MenuItem onClick={() => requestTestCaseTagsCreationUploadModal()}>Tags</MenuItem>
            </>
          )}
          <MenuItem onClick={onMoveToFolder}>Move</MenuItem>
          {folderId && (
            <MenuItem sx={{ color: 'inherit' }} onClick={onRename}>
              Rename
            </MenuItem>
          )}
          <MenuItem onClick={onDelete} sx={{ color: 'red' }}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }
);

export default ActionMenu;
